import { permissions } from '@mpk/shared/domain';

/**
 * Prmissions for the article ranges
 */
enum ArticleRangesPermissionsEnum {
  None = 0,

  /**
   * Article ranges for article search can be read and managed
   */
  ManageArticleRanges = 1,
}

export const ArticleRangesPermissions = permissions(ArticleRangesPermissionsEnum, {
  featureId: 'ArticleRanges',
});
