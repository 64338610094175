import { EnvironmentProviders } from '@angular/core';

import { ArticleMatcherPermissions } from '@mp/content-manager/article-matching/article-matcher/feature';
import { ArticleSearchPermissions } from '@mp/content-manager/artikelsuche/feature';
import { ArticleRangesPermissions } from '@mp/content-manager/core';
import { IndustryCatalogsPermissions } from '@mp/content-manager/industriekataloge/feature';
import { ClinicCatalogsPermissions } from '@mp/content-manager/klinikkataloge/feature';
import { PlatformCatalogsPermissions } from '@mp/content-manager/plattformkataloge/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';
import { permissions } from '@mpk/shared/domain';

const moduleName = 'ContentManager';

export function provideContentManagerFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Content Management',
    }),
    provideModulePermissions(
      moduleName,
      ArticleMatcherPermissions,
      ArticleSearchPermissions,
      IndustryCatalogsPermissions,
      ClinicCatalogsPermissions,
      PlatformCatalogsPermissions,
      ArticleRangesPermissions,
      UploadPermissions,
    ),
  ];
}

// TODO: Refactor upload widget folder location
enum UploadPermissionsEnum {
  None = 0,
  /**
   * Use Upload widget.
   */
  UseUpload = 1,

  /**
   * Use Upload widget and manage entries.
   */
  ManageUpload = 2 | UseUpload,
}

export const UploadPermissions = permissions(UploadPermissionsEnum, {
  featureId: 'Upload',
});
